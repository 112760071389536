import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '../stores/auth';


export const authGuard = async (to, from, next) => {
  const authStore = useAuthStore();
  const isAuthenticated = await authStore.verifyStatus();
  if (!isAuthenticated) {
    next({ name: 'LoginAdmin' });
  } else {
    next();
  }
};

const routes = [
  {
    path: '/',
    name: 'Administracao',
    component: () => import('../admin/views/principal.vue'),
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'LoginAdmin',
        component: () => import ('../admin/views/login/login.vue'),        
      },
    ]
  },
  {
    path: '/home',
    name: 'AdminHome',
    component: () => import('../admin/views/home/home.vue'),
    redirect: '/inicio',
    beforeEnter: authGuard, 
    children: [
      {
        path: '/inicio',
        name: 'Inicio',
        component: () => import ('../admin/views/inicio/inicio.vue'),
        beforeEnter: authGuard, 
      },
      {
        path: '/pedidos',
        name: 'Pedidos',
        component: () => import ('../admin/views/pedido/ListaPedidos.vue'),
        beforeEnter: authGuard, 
      },
      {
        path: '/produtos',
        name: 'Produtos',
        component: () => import ('../admin/views/produto/PrincipalProduto.vue'),
        beforeEnter: authGuard, 
      },
      {
        path: '/clientes',
        name: 'Clientes',
        component: () => import ('../admin/views/cliente/PrincipalCliente.vue'),
        beforeEnter: authGuard, 
      },
      {
        path: '/configuracoes',
        name: 'ListaUsuario',
        component: () => import ('../admin/views/configuracoes/PrincipalConfiguracoes.vue'),
        beforeEnter: authGuard, 
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
