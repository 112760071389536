import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
//import 'boxicons'
import './styles.css'
import './styles/custom.scss';

import 'boxicons'

// Importe sua função
import { formatMoeda } from './functions/converts';


const app = createApp(App);
const pinia = createPinia();

// Adicione a função ao protótipo Vue
app.config.globalProperties.$formatMoeda = formatMoeda;

app.use(pinia).use(router).mount('#app');
